document.addEventListener('DOMContentLoaded', function () {
    const burgerMenuBtn = document.getElementById('btn-burger');
    const burgerMenuCloseBtn = document.getElementById('btn-burger-close');
    const burgerMenu = document.getElementById('burger-menu');

    burgerMenuBtn.addEventListener('click', function () {
        burgerMenu.classList.toggle('burger-menu-open');
    });

    burgerMenuCloseBtn.addEventListener('click', function () {
        burgerMenu.classList.remove('burger-menu-open');
    });
});